/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const addSliderScript = () => {
  var d = document;
  var s = d.createElement("script");
  s.type = "text/javascript"
  s.src="https://unpkg.com/flickity@2.2.1/dist/flickity.pkgd.min.js"
  d.getElementsByTagName("head")[0].appendChild(s);
}

export const onClientEntry = () => {
  addSliderScript()
  let url = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDC4hfVQUrN7Vc45Wh0Qpx4cpxs1YPWE24&libraries=places"
  const script = document.createElement("script")
  script.type = "text/javascript"
  script.src = url
  script.async = true
  document.getElementsByTagName("head")[0].appendChild(script);
}
