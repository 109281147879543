// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-business-disposal-js": () => import("./../../../src/pages/business-disposal.js" /* webpackChunkName: "component---src-pages-business-disposal-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-confirmations-js": () => import("./../../../src/pages/confirmations.js" /* webpackChunkName: "component---src-pages-confirmations-js" */),
  "component---src-pages-curbside-now-js": () => import("./../../../src/pages/curbside-now.js" /* webpackChunkName: "component---src-pages-curbside-now-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-get-quote-js": () => import("./../../../src/pages/get-quote.js" /* webpackChunkName: "component---src-pages-get-quote-js" */),
  "component---src-pages-home-disposal-js": () => import("./../../../src/pages/home-disposal.js" /* webpackChunkName: "component---src-pages-home-disposal-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-manifest-report-js": () => import("./../../../src/pages/manifest-report.js" /* webpackChunkName: "component---src-pages-manifest-report-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-priceguarantee-js": () => import("./../../../src/pages/priceguarantee.js" /* webpackChunkName: "component---src-pages-priceguarantee-js" */),
  "component---src-pages-services-business-js": () => import("./../../../src/pages/services-business.js" /* webpackChunkName: "component---src-pages-services-business-js" */),
  "component---src-pages-services-home-js": () => import("./../../../src/pages/services-home.js" /* webpackChunkName: "component---src-pages-services-home-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

